import React, {useState} from 'react';
import useClickOutside from "../../../hook/useClickOutside";
import ButtonPrint from "../OrderDetailPrint/ButtonPrint";
import {gaEvents} from "../../../gaEvents";
import {useDispatch} from "react-redux";
import {getPDFLoaderSetterThunk} from "../../../store/reducers/MainReducer";

const ProjectDownloadBlock = ({order, getT,}) => {
    const dispatch = useDispatch();
    const {setVisible, ref, isVisible} = useClickOutside();
    let program_link = order.program_link;
    const onGenerateProject = () => {
        gaEvents.customEvent('klik_form', {
            gibsaw_ftp: 'Download Программы и .project',
        })
        dispatch(getPDFLoaderSetterThunk('gibsaw_ftp', order))
    }

    return (
        <div className={"print_all_documents_main_container"} ref={ref}>
            <button
                className="btn btn-app bg-info h-100 w-100"
                onClick={() => setVisible(true)}
            >
                <span className="txt">{getT("Программы и .project")}</span>
            </button>


            <div
                className={
                    "print_all_documents_container " +
                    (isVisible
                        ? "open"
                        : "")
                }
            >

                {(program_link && program_link.link) &&
                    <a href={`${program_link.link}`} download={true}
                       style={{gap: "6px", color:"#19692c", lineHeight:'18px'}}
                       className="mb-1 border-bottom pb-1 d-flex align-items-center font-weight-normal justify-content-start">
                        <i className="fa fa-download" aria-hidden="true"></i>
                        <span className={"d-block text-left"}>
                               {getT("Скачать")}
                            <br/> {order.program_link?.time}
                        </span>
                    </a>
                }
                <div className="mb-1 pb-1">
                    <div className="text-left cursor-pointer font-weight-normal" onClick={onGenerateProject}
                         style={{lineHeight:'18px'}}

                    >
                        <span className="text-warning">{getT("Сгенерировать программы и .project")}</span>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ProjectDownloadBlock;